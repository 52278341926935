import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import {
  tableColumns,
  filtersFunc,
  defaultSearchQuery,
} from '@containers/CoveragesPerCompany/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const CoveragesPerCompany = ({
  coveragesPerCompanyStore: {
    fetchTable,
    tableData,
    isLoadingTable,
    clearTableData,
  },
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  useEffect(() => {
    fetchTable({ ...defaultSearchQuery, excel: false });
    setSearchQuery(defaultSearchQuery);
    clearTableData();
  }, []);

  const clear = () => {
    clearTableData();
    setSearchQuery(defaultSearchQuery);
    fetchTable({ ...defaultSearchQuery, excel: false });
  };

  const filters = filtersFunc();

  const fetchDataOnSearch = useCallback(() => {
    fetchTable({
      coverages_from_date: searchQuery.coverages_from_date,
      coverages_to_date: searchQuery.coverages_to_date,
      excel: false,
    });
  }, [searchQuery]);

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => {
          fetchDataOnSearch();
        }}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />

      <Table
        count={100}
        disablePagination
        columns={tableColumns}
        data={tableData}
        showHideColumns
        withExcel
        excelProps={{
          excelData: tableData,
          excelFileName: 'CoveragesPerCompanyReport',
          isLoading: isLoadingTable,
          onExcelDownload: () => {},
        }}
      />
    </ContentWrapper>
  );
};

export default inject('coveragesPerCompanyStore')(
  observer(CoveragesPerCompany),
);
