import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import { tableColumns } from '@containers/SearchPhrases/constants';

const SearchPhrases = ({
  searchPhrasesStore: {
    fetchTable,
    tableData,
    isLoadingTable,
    tableDataCount,
    excelData,
    isLoadingExcel,
  },
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  useEffect(() => {
    fetchTable({ page, limit: rowsPerPage });
    setRowsPerPage(15);
    setPage(0);
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    fetchTable({
      page: newPage,
      limit: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    fetchTable({
      page: 0,
      limit: value,
    });
  };

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}

      <Table
        count={tableDataCount}
        rowsPerPageOptions={[15, 50, 100]}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={tableData}
        withExcel
        excelProps={{
          excelData,
          isLoading: isLoadingExcel,
          excelFileName: 'SearchPhrasesReport',
          onExcelDownload: () =>
            fetchTable({
              page: 0,
              limit: 99999,
              excel: true,
            }),
        }}
      />
    </ContentWrapper>
  );
};

export default inject('searchPhrasesStore')(observer(SearchPhrases));
