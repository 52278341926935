import moment from 'moment';

export const tableColumns = [
  {
    id: 'id',
    label: 'No.',
    maxWidth: 80,
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'count',
    label: 'All',
    maxWidth: 160,
  },
  {
    id: 'sum_public',
    label: 'For public sections',
    maxWidth: 160,
  },
  {
    id: 'sum_private',
    label: 'For private sections',
    maxWidth: 200,
  },
];

export const defaultSearchQuery = {
  coverages_from_date: moment().startOf('day').subtract(1, 'month').toDate(),
  coverages_to_date: moment().endOf('day').toDate(),
};

export const filtersFunc = () => [
  {
    startDateId: 'coverages_from_date',
    endDateId: 'coverages_to_date',
    showTime: false,
    label: 'Date range',
    type: 'dateRange',
  },
];
