import { useEffect, useState } from "react";
import API from '@app/api';

const useApi = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(( ) => {
      async function fetch() {
        const { data: responseData } = await API.get(url)
        setData(responseData)
        setLoading(false)
      }

      fetch();
    }, [url]);

    return { data, loading };
};

export default useApi;