import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class SearchPhrasesStore {
  isLoadingTable = false;

  tableData = [];

  tableDataCount = 0;

  excelData = [];

  isLoadingExcel = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchTable = async (query) => {
    this.isLoadingTable = !query.excel;
    this.isLoadingExcel = query.excel;
    this.excelData = [];

    const newQuery = {
      page: query.page,
      limit: query.limit,
    };

    try {
      const response = await API.get(apiRoutes.searchPhrases.index, {
        params: newQuery,
      });

      if (query.excel) {
        this.excelData = response.data.search_phrases.map((x, idx) => ({
          ...x,
          number: query.limit * query.page + idx + 1,
          for_private_sections: x.for_private_sections ? 'True' : 'False',
          for_public_sections: x.for_public_sections ? 'True' : 'False',
          modifed_by: x.updated_by,
        }));
      } else {
        this.tableData = response.data.search_phrases.map((x, idx) => ({
          ...x,
          number: query.limit * query.page + idx + 1,
          for_private_sections: x.for_private_sections ? 'True' : 'False',
          for_public_sections: x.for_public_sections ? 'True' : 'False',
          modifed_by: x.updated_by,
        }));
      }

      this.tableDataCount = response.data.search_phrases_count;
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
      this.tableData = [];
      this.excelData = [];
    } finally {
      this.isLoadingTable = false;
      this.isLoadingExcel = false;
    }
  };
}

export default new SearchPhrasesStore();
