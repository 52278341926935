import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Filters from '@components/Filters';
// eslint-disable-next-line import/no-cycle
import { filtersFuncCompanies } from '@containers/AlertsStatistics/constants';
import Text, { TAGS } from '@components/Text';
import { ChartHeader } from '@containers/AlertsStatistics/styles';
import Chart from '@containers/AlertsStatistics/components/Chart';
import Preloader from '@components/Preloader';

const AlertsPerCompany = ({
  alertsStatisticsStore: {
    fetchCompanies,
    isLoadingCompanies,
    companies,
    fetchCharts,
    dailyData,
    hourlyData,
    clearStore,
    isLoadingCharts,
  },
}) => {
  const [searchQuery, setSearchQuery] = useState({
    timeframe: 'week',
    timeframe_select_option: { label: 'this week', value: 'week' },
  });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  useEffect(() => {
    fetchCompanies();
    return clearStore;
  }, []);

  const filters = filtersFuncCompanies(companies, isLoadingCompanies);

  const search = (newValue) => {
    fetchCharts({ ...searchQuery, ...newValue }, 'company');
  };

  const keys = useMemo(() => {
    if (searchQuery.companies) {
      return searchQuery.companies.map(({ value, label, color }) => ({
        key: value.toString(),
        label,
        color,
        show: true,
      }));
    }
    return [];
  }, [searchQuery]);

  const excelKeys = useMemo(() => {
    if (searchQuery.companies) {
      return searchQuery.companies.map(({ value, label, color }) => ({
        key: value.toString(),
        label: `${label} alerts`,
        color,
        show: true,
      }));
    }
    return [];
  }, [searchQuery]);

  const companiesNames = useMemo(() => {
    if (searchQuery.companies) {
      const names = searchQuery.companies.map(({ label }) => label).join('-');
      return names.length > 11 ? 'Combined' : names;
    }
    return '';
  }, [searchQuery]);

  const anyHourlyData = useMemo(() =>
    hourlyData.some(obj => {
      const itemKeys = Object.keys(obj).filter(key => key !== 'timeFrom');
      const values = itemKeys.map(key => obj[key]);
      return values.some(value => value > 0);
    }), [hourlyData]);

  return (
    <>
      <Filters
        clear={() => {}}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) => {
          setSearchQuery({ ...searchQuery, ...valueObj });
        }}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
        autoFilter
      />

      {isLoadingCharts ? <Preloader overlay /> : null}

      {!(dailyData.length && hourlyData.length) &&
      keys.length &&
      !isLoadingCharts ? (
        <ChartHeader>
          <Text tag={TAGS.H1}>No data for given filters</Text>
        </ChartHeader>
      ) : null}

      {dailyData.length && keys.length ? (
        <Chart
          companiesNames={companiesNames}
          data={dailyData}
          keys={keys}
          dateFormat="DD-MM-YYYY"
          excelKeys={[{ key: 'date', label: 'Date' }, ...excelKeys]}
          label="Daily"
        />
      ) : null}

      {hourlyData.length && keys.length && anyHourlyData ? (
        <Chart
          companiesNames={companiesNames}
          data={hourlyData}
          keys={keys}
          dateFormat="DD-MM-YYYY hh:mm a"
          excelKeys={[
            { key: 'date', label: 'Date', format: 'dd-mm-yyyy h:mm' },
            ...excelKeys,
          ]}
          label="Hourly"
        />
      ) : null}
    </>
  );
};
export default inject('alertsStatisticsStore')(observer(AlertsPerCompany));
