import moment from 'moment';

export const tableColumns = [
  {
    id: 'number',
    label: 'No.',
    maxWidth: 55,
  },
  {
    id: 'id',
    label: 'Id',
    maxWidth: 55,
  },
  {
    id: 'phrase',
    label: 'Phrase',
    minWidth: 160,
    className: 'text-cut',
    toolTip: true,
  },
  {
    id: 'created_at',
    label: 'Created at',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
  },
  {
    id: 'updated_at',
    label: 'Updated at',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
  },
  {
    id: 'for_private_sections',
    label: 'For private sections',
    maxWidth: 110,
  },
  {
    id: 'for_public_sections',
    label: 'For public sections',
    maxWidth: 110,
  },
  {
    id: 'company_name',
    label: 'Company',
    minWidth: 160,
  },
  {
    id: 'category_names',
    label: 'Category names',
  },
  {
    id: 'created_by',
    label: 'Created by',
  },
  {
    id: 'modifed_by',
    label: 'Updated by',
  },
];
