export const filtersFunc = (requestReport, clear) => [
  {
    startDateId: 'fromDate',
    endDateId: 'toDate',
    label: 'Date range',
    showTime: false,
    type: 'dateRange',
  },
  {
    label: 'Clear date range',
    type: 'button',
    onClick: clear,
  },
  {
    label: 'Send CSV via email',
    type: 'button',
    onClick: requestReport,
  },
];
