import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class CoveragesPerCompanyStore {
  isLoadingTable = false;

  tableData = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchTable = async ({ coverages_from_date: cfd, coverages_to_date: ctd }) => {
    this.isLoadingTable = true;

    const query = {
      coverages_from_date: cfd,
      coverages_to_date: ctd,
    };

    try {
      const response = await API.get(apiRoutes.coveragesPerCompany.index, {
        params: query,
      });

      this.tableData = response.data.coverages_per_company.map((x, idx) => ({
        id: idx + 1,
        ...x,
      }));
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
      this.tableData = [];
    } finally {
      this.isLoadingTable = false;
    }
  };

  clearTableData = () => {
    this.tableData = [];
  };
}

export default new CoveragesPerCompanyStore();
