import React from 'react';
// eslint-disable-next-line import/no-cycle
import AlertsPerCompany from '@containers/AlertsStatistics/components/AlertsPerCompany';
// eslint-disable-next-line import/no-cycle
import AlertsPerSector from '@containers/AlertsStatistics/components/AlertsPerSector';

export const filtersFuncCompanies = (companies, isLoadingCompanies) => [
  {
    id: 'companies',
    label: 'Companies',
    type: 'select',
    isMulti: true,
    className: 'big',
    options: companies,
    isLoading: isLoadingCompanies,
  },
  {
    id: 'timeframe',
    label: 'Timeframe',
    type: 'select',
    className: 'big',
    options: timeframes,
  },
];

export const filtersFuncSectors = (sectors, isLoadingSectors) => [
  {
    id: 'sectors',
    label: 'Sector',
    type: 'select',
    isMulti: true,
    className: 'big',
    options: sectors,
    isLoading: isLoadingSectors,
  },
  {
    id: 'timeframe',
    label: 'Timeframe',
    type: 'select',
    className: 'big',
    options: timeframes,
  },
];

export const tabs = [
  { label: 'Alerts per company', content: <AlertsPerCompany /> },
  { label: 'Alerts per sector', content: <AlertsPerSector /> },
];

export const timeframes = [
  { value: 'week', label: 'this week' },
  { value: 'last_week', label: 'last week' },
  { value: 'month', label: 'last 4 weeks' },
];
