import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { ContentWrapper, SubTitle } from '@styles/globalStyles';
import Filters from '@components/Filters';
import {
  filtersFunc,
} from '@containers/CoveragesPerSourceReport/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const CoveragesPerSourceReport = ({ coveragesPerSourceReportStore: { requestReport } }) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] =
    useCustomSearchParams({});

  const clear = async () => {
    setSearchQuery({});
  };
  const filters = filtersFunc(() => requestReport(searchQuery), clear);

  return (
    <ContentWrapper>
      <Filters
        clear={clear}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
        autoFilter
      />
      <SubTitle>
        Request a CSV without dates to obtain results for all time.
      </SubTitle>
    </ContentWrapper>
  );
};

export default inject('coveragesPerSourceReportStore')(observer(CoveragesPerSourceReport));
