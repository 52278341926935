export default {
  signIn: '/auth/sign_in',
  profile: '/api/v1/profile',
  healthCheck: '/api/v1/healthCheck',
  users: {
    chart: '/api/v1/events/per_day',
    events: 'api/v1/events',
    counters: 'api/v1/events/counters',
    emails: 'api/v1/events/user_emails',
    subscriptions: 'api/v1/events/subscriptions',
    types: 'api/v1/events/types',
    mediums: 'api/v1/events/mediums',
  },
  contentPublications: {
    index: 'api/v1/contentPublicationsAll',
    show: 'api/v1/contentPublication',
    contentContainers: 'api/v1/contentPublicationContainersList',
  },
  emails: {
    all: 'api/v1/emails',
    chart: '/api/v1/emails/per_day',
    counters: 'api/v1/events/counters',
  },
  contentContainers: 'api/v1/contentPublicationContainersList',
  articlesAndClicks: {
    dateRangeArticlesCountPerDay:
      '/api/v1/articlesAndClicks/dateRangeArticlesCountPerDay',
    dateRangeUniqueArticlesCountPerDay:
      '/api/v1/articlesAndClicks/dateRangeUniqueArticlesCountPerDay',
    contentPublicationsWithArticlesCountPerDay:
      '/api/v1/articlesAndClicks/contentPublicationsWithArticlesCountPerDay',
    uniqueArticlesInPublicationsCountPerDay:
      '/api/v1/articlesAndClicks/uniqueArticlesInPublicationsCountPerDay',
    dateRangeUserEventsCount:
      '/api/v1/articlesAndClicks/dateRangeUserEventsCount',
  },
  archivedArticles: {
    all: 'api/v1/archivedArticles',
  },
  sentiments: {
    all: 'api/v1/sentiments',
  },
  companies: {
    search: (fragment) => `api/v1/companies?company=${fragment}`,
  },
  articleSources: {
    search: (fragment) => `api/v1/articleSources?article_source=${fragment}`,
  },
  articleSourcesMatrix: {
    stats: '/api/v1/articleSourcesStats/stats',
    avg: '/api/v1/articleSourcesStats/average',
    articleSources: '/api/v1/articleSourcesStats/articleSources',
  },
  companiesRelations: {
    graph: '/api/v1/companyRelations/graphData',
    table: '/api/v1/companyRelations/contentOrdersWithCompanies',
  },
  analystsStatistics: {
    analysts: '/api/v1/analysts',
    analystsPublications: '/api/v1/analysts/publications_per_day',
  },
  alertsStatistics: {
    companies: '/api/v1/alertsStats/companies',
    companiesColors: '/api/v1/alertsStats/companiesColors',
    sectors: '/api/v1/alertsStats/sectors',
    shibiSectors: '/api/v1/companies/sectors',
    charts: {
      sector: {
        daily: '/api/v1/alertsStats/stats/daily',
        hourly: '/api/v1/alertsStats/stats/hourly',
      },
      company: {
        daily: '/api/v1/alertsStats/stats/daily',
        hourly: '/api/v1/alertsStats/stats/hourly',
      },
    },
  },
  articleAggregates: {
    chart: '/api/v1/articleAggregates',
    distinctSources: '/api/v1/articleAggregates/distinct',
  },
  dashboard: {
    traffic: '/api/v1/events/traffic',
  },
  summaryReports: {
    briefings: '/api/v1/contentContainers/briefingTypes',
    index: '/api/v1/summariesAll',
    show: '/api/v1/summary',
  },
  contentReports: {
    analysts: '/api/v1/analysts',
    index: '/api/v1/contentsAll',
    show: '/api/v1/content',
  },
  newspapers: {
    index: '/api/v1/newspapersAll',
    articleSources: '/api/v1/newspapers/sourcesAll',
  },
  notificationReports: {
    subscriptions: (id) => `/api/v1/subscriptions/${id}`,
    emailContractsStatistics: `/api/v1/allContractsStatistics`,
  },
  nlaAggregates: {
    index: '/api/v1/nlaUsageAggregates',
    show: '/api/v1/nlaUsageAggregate',
  },
  clientCoverages: {
    index: '/api/v1/clientCoverages',
    show: '/api/v1/clientCoverage',
    lists: '/api/v1/clientCoveragesAnalystsLists',
  },
  aasReport: '/api/v1/articlesAndAlertsSummaryReport',
  aacReport: '/api/v1/articleAlertPerCompanyReport',
  briefingTypesReport: '/api/v1/briefingTypesReport',
  sourcesAttachedtoPhrases: {
    index: '/api/v1/sourcesAttachedToPhrases',
  },
  healthCheckForSources: {
    index: '/api/v1/healthCheckForSources',
  },
  coveragesPerCompany: {
    index: '/api/v1/coveragesPerCompany',
  },
  searchPhrases: {
    index: '/api/v1/searchPhrases',
  },
  coveragesPerSourceReport: '/api/v1/coveragesPerSourceReport',
  userSettingsReport: '/api/v1/userSettingsReport',
};
