import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import {
  tableColumns,
  filtersFunc,
  defaultSearchQuery,
} from '@containers/HealthCheckForSources/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const HealthCheckForSources = ({
  healthCheckForSourcesStore: { fetchTable, tableData, isLoadingTable },
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [title, setTitle] = useState('');

  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  const filteredItems = tableData.filter((x) =>
    x.title.toLowerCase().includes(title.toLowerCase()),
  );

  useEffect(() => {
    fetchTable();
    setSearchQuery(defaultSearchQuery);
  }, []);

  const clear = () => {
    setSearchQuery(defaultSearchQuery);
    setTitle('');
  };

  const filters = filtersFunc();

  const filterOnSearch = useCallback(() => {
    setTitle(searchQuery.title);
  }, [searchQuery]);

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => {
          filterOnSearch();
        }}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />

      <Table
        count={filteredItems.length}
        columns={tableColumns}
        data={filteredItems}
        disablePagination
      />
    </ContentWrapper>
  );
};

export default inject('healthCheckForSourcesStore')(
  observer(HealthCheckForSources),
);
