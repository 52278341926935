import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Filters from '@components/Filters';
import {
  filtersFunc,
  defaultSearchQuery,
} from '@containers/AASReportRequest/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const AACReportRequest = ({ aasReportRequestStore: { requestReport } }) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  const clear = async () => {
    setSearchQuery(defaultSearchQuery);
  };
  const filters = filtersFunc(() => requestReport(searchQuery, 'aac'));

  return (
    <ContentWrapper>
      <Filters
        clear={clear}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
        autoFilter
      />
    </ContentWrapper>
  );
};

export default inject('aasReportRequestStore')(observer(AACReportRequest));
