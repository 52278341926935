import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Filters from '@components/Filters';
import {
  filtersFunc,
} from '@containers/UserSettingsReport/constants';

const UserSettingsReport = ({ userSettingsReportStore: { requestReport } }) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const filters = filtersFunc(requestReport);

  return (
    <ContentWrapper>
      <Filters
        clear={() => {}}
        searchQuery={{}}
        setSearchQuery={() => {}}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
        autoFilter
      />
    </ContentWrapper>
  );
};

export default inject('userSettingsReportStore')(observer(UserSettingsReport));
