import React from 'react';
import { X } from 'tabler-icons-react';
import Dialog from './Dialog';
import { ContentBox, ContentTitle } from './styles';

const removeCollect = (emailContent) =>
  emailContent
    .replace('https://statraf-preprod-do.briefcase.news', '')
    .replace('https://www.google-analytics.com/', '')
    .replace('https://statraf.briefcase.news', '');

function HTMLDialog({
  value,
  title,
  isOpen,
  closeHandler,
  smallModal,
  backdropClass,
}) {
  return (
    <Dialog
      isOpen={isOpen}
      closeHandler={closeHandler}
      smallModal={smallModal}
      backdropClass={backdropClass || 'html-backdrop'}
    >
      <ContentTitle>
        {title}
        <X onClick={closeHandler} />
      </ContentTitle>
      <ContentBox dangerouslySetInnerHTML={{ __html: removeCollect(value) }} />
    </Dialog>
  );
}

export default HTMLDialog;
